// src/index.ts
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import './app-notification';
import { Router } from '@vaadin/router';
import { routes } from './routes';

export const router = new Router(document.querySelector('#outlet'));
if (!router.location) {
    let loc = router.location;
    console.log(router);
    console.log(loc);
    router.setRoutes(routes)
    //router.setRoutes(routes);
}
//router.setRoutes(routes);

const notyf = new Notyf();

document.querySelector('#ca-address')?.addEventListener('click', () => {
    // Your copy logic here
    const textarea = document.createElement('textarea');
    textarea.value = (document.querySelector('#ca-address') as HTMLElement).innerText.trim();
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Show notification
    notyf.success('Copied CA address to clipboard');
});